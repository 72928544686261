import React from 'react'
import ListLink from './listLink'
import style from './navLinks.module.css'

//check to swap with stateless component
class NavLinks extends React.Component {
  render() {
    return (
      <ul className={style.list}>
        <ListLink
          active={style.linkOneActive}
          listClass={style.item}
          linkClass={`${style.linkOne} ${style.link}`}
          to={'/work'}
        >
          {this.props.page}
        </ListLink>
        <ListLink
          active={style.linkTwoActive}
          listClass={style.item}
          linkClass={`${style.link} button-one dark-style`}
          to="/contact"
        >
          Contact
        </ListLink>
      </ul>
    )
  }
}

export default NavLinks
