import React from 'react'
import Logo from './logo'
import NavLinks from './navLinks'

//check to swap with stateless component
class Header extends React.Component {
  render() {
    return (
      <header>
        <nav className="main-nav">
          <Logo />
          <NavLinks page={this.props.page} />
        </nav>
      </header>
    )
  }
}

export default Header
