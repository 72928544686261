import React from 'react'
import Header from './header'
import Footer from './footer'
import '../styles/reset.css'
import './layout.css'

//check to swap with stateless component
class Layout extends React.Component {
  render() {
    return (
      <>
        {/* insert data to header through props */}
        <Header page={this.props.page} />
        {this.props.children}
        {/* insert data to footer through props */}
        <Footer page={this.props.page} />
      </>
    )
  }
}

export default Layout
