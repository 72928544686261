import React from 'react'
import { Link } from 'gatsby'

//check to swap with stateless component
class ListLink extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <li className={this.props.listClass}>
        <Link
          activeClassName={this.props.active}
          className={this.props.linkClass}
          to={this.props.to}
        >
          {this.props.children}
        </Link>
      </li>
    )
  }
}

export default ListLink
