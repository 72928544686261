import React from 'react'
import { Link } from 'gatsby'
import style from './logo.module.css'

//check to swap with stateless component
class Logo extends React.Component {
  render() {
    return (
      <Link className={style.link} to="/">
        <svg
          aria-labelledby="monodonLogoTitle monodonLogoDesc"
          className={style.logo}
          height="83.227"
          viewBox="0 0 83.078 83.227"
          role="img"
          width="83.078"
        >
          <title id="monodonLogoTitle">monodon</title>
          <desc id="monodonLogoDesc">
            the monodon logo, it slightly rotates
          </desc>
          <path
            d="M126.343,71.052A41.516,41.516,0,0,0,59,103.58a41.8,41.8,0,0,0,.839,8.34l8.349-1.7a33.052,33.052,0,0,1,32.352-39.655A32.845,32.845,0,0,1,120.2,77.074Z"
            transform="translate(-59 -62.041)"
            fill="#aee0e6"
          />
          <path
            d="M214.007,97.151l-5.27,7.274a33.042,33.042,0,0,1,3.81,28.138l8.114,2.6a41.541,41.541,0,0,0-6.654-38.014Z"
            transform="translate(-139.56 -80.931)"
            fill="#aee0e6"
          />
          <path
            d="M110.835,151.161A7.1,7.1,0,0,1,99.161,143.2,31.779,31.779,0,0,0,87.484,141a32.327,32.327,0,0,0-26.112,13.615l7.031,4.759a23.461,23.461,0,0,1,19.081-9.854,23.392,23.392,0,0,1,23.4,23.231l.784-.005a28.377,28.377,0,0,0,7.45-4.123A31.616,31.616,0,0,0,110.835,151.161Z"
            transform="translate(-60.276 -104.522)"
            fill="#aee0e6"
          />
          <g transform="translate(40.773 2.519)">
            <path
              d="M148.6,104l37.44-36.506L155.315,109.8a4.454,4.454,0,0,1-7.208-5.234A4.808,4.808,0,0,1,148.6,104Z"
              transform="translate(-147.256 -67.493)"
              fill="#aee0e6"
            />
          </g>
          <g transform="translate(4.821 56.78)">
            <path
              d="M76.752,200.5l-2.384-3.136,1.108-.842.577.513,2.7-2.533-4.989,2.06-1.331-1.751,3.32-4.256-3.161,1.919.34.693-1.108.842-2.384-3.135,1.108-.842.61.535,4.395-2.514-.355-.714L76.3,186.5l2.444,3.215-2.917,3.9,4.533-1.776,2.444,3.215L81.7,195.9l-.592-.533-3.6,3.562.353.731Z"
              transform="translate(-69.436 -185.782)"
              fill="#aee0e6"
            />
            <path
              d="M103.288,217.245c-1.094,1.69-2.837,3.241-5.6,1.452s-2.036-3.877-.936-5.578,2.848-3.235,5.623-1.439S104.4,215.524,103.288,217.245Zm-4.541-.1c.866.56,1.6.151,2.53-1.279.9-1.388.881-2.094.015-2.654s-1.6-.161-2.509,1.248S97.892,216.6,98.747,217.149Z"
              transform="translate(-83.601 -198.895)"
              fill="#aee0e6"
            />
            <path
              d="M126.82,223.117l-.807-.064L124.3,229.4l-2.687-.726-1.05-5.213-.839,3.106.729.351-.363,1.343-3.814-1.03.363-1.343.807.063,1.312-4.857-.729-.352.363-1.343,3.454.933,1.05,5.213.839-3.107-.729-.351.363-1.343,3.814,1.03Z"
              transform="translate(-94.637 -203.482)"
              fill="#aee0e6"
            />
            <path
              d="M152.74,227.875c.107,2.01-.394,4.289-3.681,4.464s-3.923-1.943-4.031-3.965.407-4.289,3.706-4.465S152.631,225.828,152.74,227.875Zm-3.731,2.588c1.03-.055,1.387-.82,1.3-2.52-.087-1.65-.516-2.212-1.545-2.157s-1.388.808-1.3,2.483S147.991,230.517,149.008,230.462Z"
              transform="translate(-110.099 -205.902)"
              fill="#aee0e6"
            />
            <path
              d="M170.132,219.265c3.116-1.176,4.417.617,5.11,2.454s.871,3.962-2.245,5.138l-3.86,1.457-.492-1.3.692-.42-1.777-4.709-.8.141-.491-1.3Zm1.86,6.019c1.244-.469,1.515-1.263.954-2.751-.535-1.419-1.254-1.811-2.5-1.342l-.547.206,1.545,4.092Z"
              transform="translate(-121.536 -203.207)"
              fill="#aee0e6"
            />
            <path
              d="M196.532,208.862c1.228,1.6,2.107,3.757-.5,5.766s-4.334.622-5.57-.983-2.1-3.765.523-5.781S195.281,207.237,196.532,208.862Zm-1.608,4.248c.817-.629.678-1.463-.36-2.812-1.008-1.31-1.68-1.53-2.5-.9s-.686,1.452.338,2.782S194.117,213.732,194.924,213.11Z"
              transform="translate(-133.908 -196.739)"
              fill="#aee0e6"
            />
            <path
              d="M209.318,185.74l-.332.738,5.393,3.757-1.591,2.284-5.261-.773,2.64,1.84.577-.567,1.142.8-2.258,3.242-1.142-.8.332-.738-4.129-2.876-.577.567-1.142-.8,2.045-2.936,5.262.772-2.641-1.839-.577.567-1.142-.8,2.259-3.242Z"
              transform="translate(-141.279 -184.945)"
              fill="#aee0e6"
            />
          </g>
        </svg>
      </Link>
    )
  }
}

export default Logo
