import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

//check to swap with stateless component
const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          url
          image
          imageWidth
          imageHeight
        }
      }
    }
  `)

  return (
    <Helmet title={`${title} | ${data.site.siteMetadata.title}`}>
      <meta
        property="og:title"
        content={`${title} | ${data.site.siteMetadata.title}`}
      />
      <meta
        property="og:description"
        content={`${data.site.siteMetadata.description}`}
      />
      <meta property="og:url" content={`${data.site.siteMetadata.url}`} />
      <meta property="og:image" content={`${data.site.siteMetadata.image}`} />
      <meta
        property="og:image:width"
        content={`${data.site.siteMetadata.imageWidth}`}
      />
      <meta
        property="og:image:height"
        content={`${data.site.siteMetadata.imageHeight}`}
      />
    </Helmet>
  )
}

export default Head
