import React from 'react'
import Logo from './logo'
import { graphql, useStaticQuery } from 'gatsby'
import NavLinks from './navLinks'

const Footer = props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            brand
            author
            mail
          }
        }
      }
    `
  )
  const date = new Date()
  const currentYear = date.getFullYear()
  return (
    <footer>
      <nav className="main-nav">
        <Logo />
        <small>
          ©{currentYear} {data.site.siteMetadata.brand} -{' '}
          {data.site.siteMetadata.author} /{' '}
          <a
            href={`mailto:${data.site.siteMetadata.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.site.siteMetadata.mail}
          </a>
        </small>
        <NavLinks page={props.page} />
      </nav>
    </footer>
  )
}

export default Footer
