import React from 'react'
import style from './textHighlight.module.css'

//check to swap with stateless component
class TextHighlight extends React.Component {
  render() {
    return <span className={style.color}>{this.props.children}</span>
  }
}

export default TextHighlight
